<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-transparent md-absolute"
    :class="extraNavClasses"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start wrappersize">
        <h3 class="md-title fontsize">
          {{ $t("navbar.top3") }}
          <a class="hide-desktop" href="tel:08-6618355"
            ><i class="fas fa-phone"></i
          ></a>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section-start="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">layers</i>
                        <p>{{ $t("navbar.artical") }}</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="story in contentData" :key="story.uuid">
                          <a :href="'/page/' + story.slug">
                            <i data-nosnippet class="material-icons">
                                <span data-nosnippet>{{ story.content.menu_icon
                            }}</span>
                                </i>
                            <p>{{ story.content.menu_title }}</p>
                          </a>
                        </li>
                      </ul>

                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i data-nosnippet class="material-icons"
                          >view_carousel</i
                        >
                        <p>{{ $t("navbar.professional") }}</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="/landing">
                            <i data-nosnippet class="material-icons"
                              >view_day</i
                            >
                            <p>{{ $t("navbar.status") }}</p>
                          </a>
                        </li>

                        <li>
                          <a href="/nezikin">
                            <i data-nosnippet class="material-icons"
                              >account_circle</i
                            >
                            <p>{{ $t("navbar.nezikin") }}</p>
                          </a>
                        </li>

                        <li>
                          <a href="/page/compensation-road-accidents">
                            <i data-nosnippet class="material-icons">adjust</i>
                            <p>{{ $t("navbar.tahonot_drachim") }}</p>
                          </a>
                        </li>

                        <li>
                          <a href="/page/accidents">
                            <i data-nosnippet class="material-icons"
                              >local_hospital</i
                            >
                            <p>{{ $t("navbar.tahonot_havoda") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/page/defamation">
                            <i data-nosnippet class="material-icons"
                              >record_voice_over</i
                            >
                            <p>{{ $t("navbar.defamation") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/nezikin">
                            <i data-nosnippet class="material-icons"
                              >directions_walk</i
                            >
                            <p>{{ $t("navbar.bitoach_leumi") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/nezikin">
                            <i data-nosnippet class="material-icons"
                              >fiber_smart_record</i
                            >
                            <p>{{ $t("navbar.misrad_bitachon") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/nezikin">
                            <i data-nosnippet class="material-icons">error</i>
                            <p>{{ $t("navbar.rashlanot") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/legaldoc">
                            <i data-nosnippet class="material-icons">face</i>
                            <p>{{ $t("navbar.zavahot") }}</p>
                          </a>
                        </li>
                        <li>
                          <a href="/legaldoc">
                            <i data-nosnippet class="material-icons"
                              >done_all</i
                            >
                            <p>{{ $t("navbar.yipuy") }}</p>
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <md-list-item href="/" v-if="showDownload">
                <i class="material-icons">cloud_download</i>
                <p>{{ $t("navbar.homepage") }}</p>
              </md-list-item>

              <md-list-item :href="facebookLink" target="_blank">
                <i class="fab fa-facebook-square"></i>
                <p class="hidden-lg">Facebook</p>

                <md-tooltip md-direction="bottom"
                  >Like us on Facebook</md-tooltip
                >
              </md-list-item>

              <md-list-item>
                <LocaleSwitcher />
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import StoryblokClient from "storyblok-js-client";

let Storyblok = new StoryblokClient({
  accessToken: "q53wjs4yTcLOyiW1e1WDZAtt"
});

export default {
  components: {
    MobileMenu,
    LocaleSwitcher
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      contentData: []
    };
  },

  computed: {
    showDownload() {
      const excludedRoutes = ["login", "profile", "index"];
      return excludedRoutes.every(r => r !== this.$route.name);
    },
    facebookLink() {
      return this.$i18n.locale == "ru"
        ? "https://www.facebook.com/Shatzki.Law"
        : "https://www.facebook.com/Law.Shatzki/";
    }
  },

  methods: {
    loadStoryblok() {
      console.log("this.$i18n.locale: " + this.$i18n.locale);
      Storyblok.get("cdn/stories?starts_with=" + this.$i18n.locale + "/*", {
        filter_query: {
          component: {
            in: "page1"
          }
        },
        excluding_fields: "description, Subtitle, title, subtitle"
      })
        .then(stories => {
          this.contentData = stories.data.stories;
        })
        .catch(error => {
          console.log(error);
        });
    },

    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);

    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.loadStoryblok();
      },
      { immediate: true }
    );
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style lang="scss">
.md-title a {
  margin-left: 10px;
}

.fontsize {
  font-size: 16px !important;
  white-space: normal !important;
}

.wrappersize {
  min-width: 310px !important;
}

@media (min-width: 904px) {
  .hide-desktop {
    display: none;
  }

  .fontsize {
    font-size: 18px !important;
  }
}
</style>
