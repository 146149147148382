<template>
  <div id="material-kit">
    <div :class="{ 'nav-open': NavbarStore.showNavbar }">
      <router-view name="header" />
      <div>
        <router-view />
      </div>
      <router-view name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "משרד עורכי דין: סלבה שצקי עורך דין",
    titleTemplate: "%s | נזיקין | ביטוח לאומי | הסדרת מעמד | דין אזרחי-מסחרי"
  }
  // ...
};
</script>
