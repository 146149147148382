<template>
  <div class="info" v-bind:style="{ direction }">
    <div class="icon icon-info">
      <md-icon>{{ contentIcon }}</md-icon>
    </div>
    <h4 class="info-title">{{ contentHtml }}</h4>

    <span v-html="contentHtmlbody"></span>
  </div>
</template>

<script>
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
const resolver = new RichTextResolver();

export default {
  name: "LandingItem",
  props: {
    content: {},
    direction: {
      type: String,
      Defaults: "rtl"
    }
  },
  watch: {
    content(val, oldVal) {
      this.contentHtml = this.getContentHtml();
      this.contentHtmlbody = this.getContentHtmlbody();
      this.contentIcon = this.getContentIcon();
    }
  },
  data() {
    return {
      contentHtml: this.getContentHtml(),
      contentHtmlbody: this.getContentHtmlbody(),
      contentIcon: this.getContentIcon()
    };
  },
  methods: {
    getContentHtml() {
      return this.content.title;
    },
    getContentHtmlbody() {
      return this.content.description
        ? resolver.render(this.content.description)
        : "";
    },
    getContentIcon() {
      return this.content.Icon;
    }
  }
};
</script>
