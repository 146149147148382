<template>
  <div class="md-layout-item md-small-size-100" v-bind:style="{ direction }">
    <div class="team-player">
      <md-card class="md-card-plain">
        <h4 class="card-title imagesize" v-html="contentHtml"></h4>
        <img style="width: 30px; display: inline-block" :src="googlelogo" />
        <span style="width: 70px" class="stars"></span>
        <md-card-content>
          <p class="card-description" v-html="contentHtmlbody"></p>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
const resolver = new RichTextResolver();

export default {
  name: "GoogleReview",
  props: {
    content: {},
    direction: {
      type: String,
      Defaults: "rtl"
    },
    googlelogo: {
      type: String,
      default: require("@/assets/img/7123025_logo_google_g_icon.svg")
    }
  },
  watch: {
    content(val, oldVal) {
      this.contentHtml = this.getContentHtml();
      this.contentHtmlbody = this.getContentHtmlbody();
    }
  },
  data() {
    return {
      contentHtml: this.getContentHtml(),
      contentHtmlbody: this.getContentHtmlbody()
    };
  },
  methods: {
    getContentHtml() {
      return this.content.Description
        ? resolver.render(this.content.Description)
        : "";
    },
    getContentHtmlbody() {
      return this.content.Description
        ? resolver.render(this.content.Description_Body)
        : "";
    }
  }
};
</script>

<style lang="scss">
.stars {
  height: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAZklEQVR4AWL4uYWZCdAJHVMBAIJAAG1DoAtjGarYxxjqwHADnA8HFuWL3E8puH8gAqILPaB34Qq4FAQ1yoo+8ES7NRWKe6u+Ogo0XjtaCmlSd6K/4OQQOF0JKYTsMasgOIRkBfDZATita/85jgoHAAAAAElFTkSuQmCC);
  background-size: 14px 13px;
  background-repeat: repeat-x;
  display: inline-block;
  margin: 0 10px 0 0;
}
.imagesize {
  direction: ltr;
  line-height: 19px;
  img {
    width: 40px !important;
    height: 40px !important;
  }
  span,
  b {
    font-size: 14px !important;
  }
  p:nth-child(2) {
    margin: 0px;
  }
}
</style>
