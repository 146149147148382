<template>
  <!--
<ul>
  <li v-for="locale in locales" :key="locale" ">
    {{locale}}
  </li>
</ul>
-->
  <div style="height: 25px; width: 25px">
    <img
      v-show="showFlag('ru')"
      @click="switchLocale('ru')"
      src="../assets/img/RU-Russia-Flag-icon.png"
      width="26"
    />
    <img
      v-show="showFlag('he')"
      @click="switchLocale('he')"
      src="../assets/img/flag-400.png"
      width="26"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      //locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    };
  },

  computed: {
    ...mapState("locale", ["locale"])
  },
  created() {
    this.$i18n.locale = this.locale;
  },
  methods: {
    ...mapMutations("locale", ["setLocale"]),
    isCurrentLocale(locale) {
      return this.locale === locale;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.setLocale(locale);
      }
    },
    showFlag(locale) {
      return this.locale !== locale;
    }
  }
};
</script>

<style scoped>
li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}

img {
  margin-left: 6px;
  cursor: pointer;
}

/*div {
    display: block;
}*/
</style>
