import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

/*

var firebaseConfig = {
    apiKey: "AIzaSyBGn8sPC57WN9uYU3UCM_BVKAkaWqcaslI",
    authDomain: "slava-6ea2a.firebaseapp.com",
    databaseURL: "https://slava-6ea2a.firebaseio.com",
    projectId: "slava-6ea2a",
    storageBucket: "slava-6ea2a.appspot.com",
    messagingSenderId: "46071823736",
    appId: "1:46071823736:web:a63e6b4bc69f13156b1c8d",
    measurementId: "G-BLSVKZ0QVX"
  };

*/

var firebaseConfig = {
  apiKey: "AIzaSyBGn8sPC57WN9uYU3UCM_BVKAkaWqcaslI",
  authDomain: "",
  databaseURL: "",
  projectId: "slava-6ea2a",
  storageBucket: "",
  messagingSenderId: "",
  appId: "1:46071823736:web:a63e6b4bc69f13156b1c8d"
};

firebase.initializeApp(firebaseConfig);
/*firebase.analytics();*/

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection("users");
const leadsCollection = db.collection("leads");

// export utils/refs
export { db, auth, usersCollection, leadsCollection };
