export default {
  namespaced: true,
  state: {
    locale: "he"
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    }
  }
};
