<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100 text-center"
          >
            <h1 class="title">עריכת מסמכים משפטיים</h1>

            <h4>
              <span v-for="landing in contentData" :key="landing._uid">
                {{ landing.content.title }}
                <br />
              </span>
            </h4>

            <br />
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section" v-bind:style="{ direction }">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">עריכת מסמכים משפטיים</h2>
              <h5 class="description">
                <span v-for="landing in contentData" :key="landing._uid">
                  {{ landing.content.title }}
                  <br />
                </span>
              </h5>
            </div>
          </div>
          <div dir="rtl" class="features text-center">
            <div
              class="md-layout"
              v-for="(row, rowIndex) in chunkedContentData"
              :key="rowIndex"
            >
              <div
                class="md-layout-item md-medium-size-33 md-small-size-100"
                v-for="landingItem in row"
                :key="landingItem._uid"
              >
                <landing-item
                  v-bind:direction="direction"
                  v-bind:content="landingItem.content"
                ></landing-item>
              </div>
            </div>
          </div>

          <br />
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">
                משרדנו בעל ניסיון רב שנים בעריכת ייפוי כח מתמשך, צוואות וירושות, נשמח להעניק לכם שירות משפטי.
              </h2>
              <h5 class="description">
                <span>
                  תפנו אלינו לקבלת סיוע משפטי וליווי מקצועי.
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingItem from "@/views/components/LandingItem";
import Storyblok from "@/storyblok";

export default {
  components: { LandingItem },
  bodyClass: "landing-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/f1101344-33de-444d-9177-b6d84221114b.jpeg")
    },
    teamImg1: {
      type: String,
      default: require("@/assets/img/faces/avatar.jpg")
    },
    teamImg2: {
      type: String,
      default: require("@/assets/img/faces/christian.jpg")
    },
    teamImg3: {
      type: String,
      default: require("@/assets/img/faces/kendall.jpg")
    }
  },
  data() {
    return {
      contentData: "",
      name: null,
      email: null,
      message: null,
      tabPane1: [
        { image: require("@/assets/img/examples/studio-1.jpg") },
        { image: require("@/assets/img/examples/studio-2.jpg") },
        { image: require("@/assets/img/examples/studio-4.jpg") },
        { image: require("@/assets/img/examples/studio-5.jpg") }
      ],
      tabPane2: [
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/cynthia-del-rio.jpg") },
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/clem-onojegaw.jpg") }
      ],
      tabPane3: [
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/studio-3.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/studio-1.jpg") }
      ]
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    direction() {
      return this.$i18n.locale == "ru" ? "ltr" : "rtl";
    },
    chunkedContentData() {
      // Splitting the contentData into chunks of size 3
      const chunkSize = 3;
      const result = [];
      for (let i = 0; i < this.contentData.length; i += chunkSize) {
        result.push(this.contentData.slice(i, i + chunkSize));
      }
      return result;
    }
  },

  methods: {
    loadStoryblok() {
      console.log("this.$i18n.locale: " + this.$i18n.locale);
      Storyblok.get("cdn/stories?starts_with=" + this.$i18n.locale + "/*", {
        filter_query: {
          component: {
            in: "Post"
          }
        },
        with_tag: ["LegalDoc"]
      })
        .then(stories => {
          this.contentData = stories.data.stories;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.loadStoryblok();
      },
      { immediate: true }
    );
  }
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
