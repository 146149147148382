import Vue from "vue";
import Router from "vue-router";
import Landing from "./views/Landing.vue";
import Nazikin from "./views/Nazikin.vue";
import Profile from "./views/Profile.vue";
import Page from "./views/Page.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import LegalDoc from "@/views/LegalDoc.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru"
    },
    {
      path: "/landing",
      name: "landing",
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru/landing"
    },
    {
      path: "/nezikin",
      name: "nezikin",
      components: { default: Nazikin, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru/nezikin"
    },
    {
      path: "/legaldoc",
      name: "legaldoc",
      components: { default: LegalDoc, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru/legaldoc"
    },
    {
      path: "/profile",
      name: "profile",
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru/profile"
    },
    {
      path: "/page/:id",
      name: "page",
      components: { default: Page, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" }
      },
      alias: "/ru/page/:id"
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
