<template>
  <div dir="rtl" class="wrapper">
    <parallax
      class="section page-header header-filter"
      :style="headerStyle"
    ></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img
                    :src="img"
                    alt="סלבה"
                    class="img-raised rounded-circle img-fluid"
                  />
                  <h6 class="fixposistion">{{ $t("navbar.misrad") }}</h6>
                </div>
                <div class="name">
                  <h3 class="title">{{ $t("profile.title") }}</h3>
                  <h6>{{ $t("profile.lawyer") }}</h6>
                  <md-button
                    :href="facebookLink"
                    class="md-just-icon md-simple md-dribbble"
                    ><i class="fab fa-facebook-square"></i
                  ></md-button>

                  <md-button
                    href="javascript:void(0)"
                    class="md-just-icon md-simple hide-desktop"
                    ><LocaleSwitcher
                  /></md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="description text-center" v-bind:style="{ direction }">
            <p v-html="$t('profile.p1')"></p>
          </div>
          <br />

          <div class="section ">
            <div class="container">
              <div class="md-layout">
                <div
                  class="md-layout-item md-xsmall-size-100 mx-auto text-center"
                >
                  <h6>{{ $t("profile.ourexpert") }}</h6>
                </div>
              </div>

              <div class="md-layout" v-bind:style="{ direction }">
                <div class="md-layout-item md-xsmall-size-100 cursor">
                  <a href="/nezikin">
                    <light-card class="sectionsize">
                      <span slot="description">
                        <p>
                          <br />
                          <strong>{{ $t("profile.torts") }}</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              {{ $t("profile.tortsA") }}
                            </p>
                          </li>
                          <li>
                            <p>{{ $t("profile.tortsB") }} <br /></p>
                          </li>
                          <li>
                            <p>{{ $t("profile.tortsC") }} <br /></p>
                          </li>
                          &nbsp;
                        </ul>
                        <p>
                          <u>{{ $t("profile.readmore") }} </u> <br />
                        </p>
                      </span>
                    </light-card>
                  </a>
                </div>
                <div class="md-layout-item md-xsmall-size-100 cursor">
                  <a href="/landing">
                    <light-card class="sectionsize">
                      <span slot="description">
                        <br />
                        <p>
                          <strong>{{ $t("profile.immigration") }} </strong>
                          <br />
                        </p>
                        <ul>
                          <li>
                            <p>
                              {{ $t("profile.immigrationA") }}
                            </p>
                          </li>
                          <li>
                            <p>{{ $t("profile.immigrationB") }}<br /></p>
                          </li>
                          <li>
                            <p>
                              {{ $t("profile.immigrationC") }}
                            </p>
                          </li>
                        </ul>
                        <p>
                          <u>{{ $t("profile.readmore") }}</u> <br />
                        </p>
                      </span>
                    </light-card>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="section text-center" style="direction: ltr">
            <div class="container">
              <h2 class="title">המלצות של לקוחות על עו"ד סלבה שצקי</h2>
              <div class="team">
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-100 mx-auto md-small-size-100"
                  >
                    <carousel
                      :per-page="3"
                      :scrollPerPage="false"
                      loop
                      :speed="700"
                      :autoplay-timeout="5000"
                      :mouse-drag="false"
                      :perPageCustom="[
                        [375, 1],
                        [480, 1],
                        [768, 3]
                      ]"
                      navigationEnabled
                      navigationNextLabel="<i class='material-icons md-small-hide'>keyboard_arrow_right</i>"
                      navigationPrevLabel="<i class='material-icons md-small-hide'>keyboard_arrow_left</i>"
                    >
                      <slide
                        v-for="reviewItem in contentData.ReviewItem"
                        :key="reviewItem._uid"
                      >
                        <google-review
                          v-bind:direction="direction"
                          v-bind:content="reviewItem"
                        ></google-review>
                      </slide>
                    </carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section section-contacts">
            <div class="container">
              <div class="md-layout">
                <div
                  v-bind:style="{ direction }"
                  v-if="!showPostContact"
                  class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto"
                >
                  <h2 class="text-center title">{{ $t("profile.contact") }}</h2>
                  <h4 class="text-center description">
                    {{ $t("profile.precontact") }}
                    <br />

                    <a href="tel:08-6618355">
                      <i class="fas fa-phone"></i>
                      {{ $t("profile.phone") }} 08-6618355
                    </a>
                    <br />

                    <a href="https://wa.me/9720555524224">
                      <i class="fab fa-whatsapp"></i>
                      {{ $t("profile.whatsapp") }} 055-5524224
                    </a>
                    <br />
                    <i class="fas fa-map-marker-alt"></i>
                    {{ $t("profile.address") }} יריחו 5, קומה 4, אשקלון
                    <br /><br />
                  </h4>
                  <h4 class="text-center">{{ $t("profile.details") }}</h4>
                  <br />
                  <form class="contact-form">
                    <div class="md-layout">
                      <div class="md-layout-item md-size-50">
                        <md-field :class="getValidationClass('name')">
                          <label>שם</label>
                          <md-input v-model="form.name" type="text"></md-input>
                          <span class="md-error" v-if="!$v.form.name.required"
                            >נא למלא שם בבקשה</span
                          >
                          <span
                            class="md-error"
                            v-else-if="!$v.form.name.minlength"
                            >נא למלא שם מלא בבקשה</span
                          >
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-50">
                        <md-field :class="getValidationClass('email')">
                          <label>כותבת דואר אלקטרוני</label>
                          <md-input
                            v-model="form.email"
                            type="email"
                          ></md-input>
                          <span class="md-error" v-if="!$v.form.email.required"
                            >חסר כתובת אימייל</span
                          >
                          <span
                            class="md-error"
                            v-else-if="!$v.form.email.email"
                            >מייל לא תיקני</span
                          >
                        </md-field>
                      </div>
                    </div>
                    <md-field maxlength="5">
                      <label>הודעה</label>
                      <md-textarea v-model="form.message"></md-textarea>
                    </md-field>
                    <br /><br />
                    <div class="md-layout">
                      <div
                        class="md-layout-item md-size-33 mx-auto text-center"
                      >
                        <md-button @click="post()" class="md-success"
                          >שלח הודעה</md-button
                        >
                      </div>
                    </div>
                  </form>
                </div>

                <transition name="fade">
                  <div class="description text-center" v-if="showPostContact">
                    תודה, נהיה בקשר.
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { leadsCollection } from "@/firebase";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { validationMixin } from "vuelidate";
import { LightCard } from "@/components";
import { required, email, minLength } from "vuelidate/lib/validators";
import StoryblokClient from "storyblok-js-client";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import GoogleReview from "@/views/components/GoogleReview";

let Storyblok = new StoryblokClient({
  accessToken: "q53wjs4yTcLOyiW1e1WDZAtt"
});

const resolver = new RichTextResolver();

export default {
  bodyClass: "profile-page",
  mixins: [validationMixin],
  components: { GoogleReview, LightCard, LocaleSwitcher },
  data() {
    return {
      contentData: "",

      classicModal: false,
      classicModalNezikin: false,
      form: {
        message: null,
        email: null,
        name: null
      },

      showPostContact: false
    };
  },
  props: {
    header: {
      type: String,
      default: "https://a.storyblok.com/f/140719/1800x1200/01c8c92e2a/legal_court_justice_lawyer_18x12-38f3df0a.png/m/"
    },
    img: {
      type: String,
      default: "https://a.storyblok.com/f/140719/640x640/5f79bc5d2f/slava-27641213-1.png/m/"
    },
    imgb: {
      type: String,
      default: "https://a.storyblok.com/f/140719/640x640/5f79bc5d2f/slava-27641213-1.png/m/"
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    direction() {
      return this.$i18n.locale == "ru" ? "ltr" : "rtl";
    },
    facebookLink() {
      return this.$i18n.locale == "ru"
        ? "https://www.facebook.com/Shatzki.Law"
        : "https://www.facebook.com/Law.Shatzki/";
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.loadStoryblok();
      },
      { immediate: true }
    );
  },

  methods: {
    classicModalHide() {
      this.classicModal = false;
    },
    classicModalNezikinHide() {
      this.classicModalNezikin = false;
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    loadStoryblok() {
      Storyblok.get(
        "cdn/stories/google-reviews-rich/?language=" + this.$i18n.locale
      )
        .then(response => {
          this.contentData = response.data.story.content;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async post() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await leadsCollection.add({
          name: this.form.name,
          message: this.form.message,
          email: this.form.email,
          createdOn: new Date()
        });

        this.showPostContact = true;
      }
    }
  }
};
</script>

<style lang="scss">
.arrow-color {
  color: black !important;
}

.VueCarousel-navigation-prev i,
.VueCarousel-navigation-next i {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.VueCarousel-navigation-next {
  transform: translateY(-81%) translateX(157%) !important;
}

.VueCarousel-navigation-prev {
  transform: translateY(-81%) translateX(-136%) !important;
}

.fixposistion {
  color: whitesmoke;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
  top: -200px;
}

.clear-padding {
  padding: 0px !important;
}

p {
  font-size: 16px;
}

.section {
  padding: 0;
}

.md-layout-item label {
  text-align: right;
  right: 0px;
}

.md-layout-item strong {
  color: #555555;
  font-size: 20px;
}

.md-layout-item ul {
  padding-inline-start: 10px;
  line-height: 16px;
}

.cursor {
  cursor: pointer;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img {
      margin-bottom: 2.142rem;
    }
  }
}

@media (min-width: 804px) {
  .hide-desktop {
    display: none;
  }

  .sectionsize {
    min-height: 210px;
  }
}
</style>
