<template>
  <div dir="rtl" class="wrapper">
    <parallax
      class="section page-header header-filter"
      :style="headerStyle"
    ></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item mx-auto">
              <div class="profile">
                <div class="avatar space-70"></div>
                <div class="name">
                  <h3 class="title">{{ contentData.title }}</h3>
                  <h6>{{ contentData.Subtitle }}</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="space-50" />

          <div
            class="contentHTML"
            v-bind:style="{ direction }"
            v-html="contentHtml"
          ></div>

          <div class="space-50" />

          <div class="section ">
            <div class="container">
              <div class="md-layout" v-bind:style="{ direction }">
                <div class="md-layout-item md-xsmall-size-100 cursor"></div>
                <div class="md-layout-item md-xsmall-size-100 cursor"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { leadsCollection } from "@/firebase";
import { validationMixin } from "vuelidate";
import StoryblokClient from "storyblok-js-client";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import { required, email, minLength } from "vuelidate/lib/validators";

let Storyblok = new StoryblokClient({
  accessToken: "q53wjs4yTcLOyiW1e1WDZAtt"
});

const resolver = new RichTextResolver();

export default {
  bodyClass: "profile-page",
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      classicModal: false,
      classicModalNezikin: false,
      form: {
        message: null,
        email: null,
        name: null
      },
      contentData: "",
      contentHtml: "",
      showPostContact: false
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/legal_court_justice_lawyer_18x12.png")
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/slava.png")
    }
  },
  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.loadStoryblok();
      },
      { immediate: true }
    );
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    direction() {
      return this.$i18n.locale == "ru" ? "ltr" : "rtl";
    },
    facebookLink() {
      return this.$i18n.locale == "ru"
        ? "https://www.facebook.com/Shatzki.Law"
        : "https://www.facebook.com/Law.Shatzki/";
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    classicModalHide() {
      this.classicModal = false;
    },
    classicModalNezikinHide() {
      this.classicModalNezikin = false;
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },

    loadStoryblok() {
      Storyblok.get(
        "cdn/stories/" +
          this.$route.params.id +
          "?language=" +
          this.$i18n.locale
      )
        .then(response => {
          this.contentData = response.data.story.content;
          this.contentHtml = resolver.render(this.contentData.description);
        })
        .catch(error => {
          console.log(error);
        });
    },

    async post() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await leadsCollection.add({
          name: this.form.name,
          message: this.form.message,
          email: this.form.email,
          createdOn: new Date()
        });

        this.showPostContact = true;
      }
    }
  }
};
</script>

<style lang="scss">
.title {
  font-size: 2rem !important;
}

.name h6 {
  line-height: 40px;
  font-size: 1.5rem !important;
}

.main-raised {
  margin-top: -128px !important;
}

.contentHTML p {
  font-size: 1.1em !important;
  max-width: 800px;
  line-height: 26px;
}

.clear-padding {
  padding: 0px !important;
}

p {
  font-size: 18px !important;
}

.section {
  padding: 0;
}

.md-layout-item label {
  text-align: right;
  right: 0px;
}

.md-layout-item strong {
  color: #555555;
  font-size: 20px;
}

.md-layout-item ul {
  padding-inline-start: 10px;
  line-height: 16px;
}

.cursor {
  cursor: pointer;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img {
      margin-bottom: 2.142rem;
    }
  }
}

@media (min-width: 804px) {
  .hide-desktop {
    display: none;
  }
}
</style>
