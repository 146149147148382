<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100 text-center"
          >
            <h1 class="title">{{ $t("nezikin.nezikin") }}</h1>

            <h4>
              {{ $t("navbar.bitoach_leumi") }}
              <br />
              {{ $t("nezikin.excident") }}
              <br />
              {{ $t("nezikin.excident_work") }}
              <br />
              {{ $t("nezikin.excident_personal") }}
              <br />
            </h4>

            <br />
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section" v-bind:style="{ direction }">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">{{ $t("navbar.bitoach_leumi") }}</h2>

            </div>
          </div>


          <div dir="rtl" class="features text-center">
            <div class="md-layout">

            <div
                class="md-layout-item-33 md-medium-size-33 md-small-size-100"
                v-for="landingItem in contentData"
                :key="landingItem._uid"
              >
                <landing-item
                  v-bind:direction="direction"
                  v-bind:content="landingItem.content"
                ></landing-item>
              </div>

            </div>
          </div>

          <br/><br/>

          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">תביעות נגד חברת ביטוח</h2>

            </div>
          </div>

          <div dir="rtl" class="features text-center">
            <div class="md-layout">

            <div
                class="md-layout-item-33 md-medium-size-33 md-small-size-100"
                v-for="landingItem in contentDataInsurance"
                :key="landingItem._uid"
              >
                <landing-item
                  v-bind:direction="direction"
                  v-bind:content="landingItem.content"
                ></landing-item>
              </div>

            </div>
          </div>

                    <br/><br/>

          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">משרד הביטחון</h2>

            </div>
          </div>

          <div dir="rtl" class="features text-center">
            <div class="md-layout">

            <div
                class="md-layout-item md-medium-size md-small-size-100"
                v-for="landingItem in contentDataBitahon"
                :key="landingItem._uid"
              >
                <landing-item
                  v-bind:direction="direction"
                  v-bind:content="landingItem.content"
                ></landing-item>
              </div>

            </div>
          </div>

                              <br/><br/>

          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">לשון הרע</h2>

            </div>
          </div>

          <div dir="rtl" class="features text-center">
            <div class="md-layout">

            <div
                class="md-layout-item md-medium-size md-small-size-100"
                v-for="landingItem in contentDataLashon"
                :key="landingItem._uid"
              >
                <landing-item
                  v-bind:direction="direction"
                  v-bind:content="landingItem.content"
                ></landing-item>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storyblok from "@/storyblok";
import LandingItem from "@/views/components/LandingItem.vue";

export default {
  metaInfo: {
    titleTemplate: "%s | נזיקין"
  },

  components: {
    LandingItem

  },
  bodyClass: "landing-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/front-lawyer.jpeg")
    },
    teamImg1: {
      type: String,
      default: require("@/assets/img/faces/avatar.jpg")
    },
    teamImg2: {
      type: String,
      default: require("@/assets/img/faces/christian.jpg")
    },
    teamImg3: {
      type: String,
      default: require("@/assets/img/faces/kendall.jpg")
    }
  },
  data() {
    return {
      contentData: "",
      contentDataInsurance: "",
      contentDataBitahon: "",
      contentDataLashon: "",

      name: null,
      email: null,
      message: null,
      tabPane1: [
        { image: require("@/assets/img/examples/studio-1.jpg") },
        { image: require("@/assets/img/examples/studio-2.jpg") },
        { image: require("@/assets/img/examples/studio-4.jpg") },
        { image: require("@/assets/img/examples/studio-5.jpg") }
      ],
      tabPane2: [
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/cynthia-del-rio.jpg") },
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/clem-onojegaw.jpg") }
      ],
      tabPane3: [
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/studio-3.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/studio-1.jpg") }
      ]
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    direction() {
      return this.$i18n.locale == "ru" ? "ltr" : "rtl";
    },

  },

  methods: {
    loadStoryblok() {
      console.log("this.$i18n.locale: " + this.$i18n.locale);

      const tags = ["Accident", "Insurance", "Bitahon", "Lashon"];
      const requests = tags.map(tag => {
        return Storyblok.get("cdn/stories", {
          starts_with: this.$i18n.locale + "/*",
          filter_query: {
            component: {
              in: "Post"
            }
          },
          with_tag: [tag]
        });
      });

      Promise.all(requests)
        .then(responses => {
          responses.forEach((response, index) => {
            const stories = response.data.stories;
            switch (tags[index]) {
              case "Accident":
                this.contentData = stories;
                break;
              case "Insurance":
                this.contentDataInsurance = stories;
                break;
              case "Bitahon":
                this.contentDataBitahon = stories;
                break;
              case "Lashon":
                this.contentDataLashon = stories;
                break;
              default:
                break;
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.loadStoryblok();
      },
      { immediate: true }
    );
  }

};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}

.info {
  padding: 70px 20px 30px;
}
</style>
