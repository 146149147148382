import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { auth } from "./firebase";
import MaterialKit from "./plugins/material-kit";
import i18n from "./i18n";
import store from "./store";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(MaterialKit);

router.beforeEach((to, from, next) => {
  if (to.path.includes("/ru")) {
    store.commit("locale/setLocale", "ru");
  }

  next();
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      i18n,
      store: store,
      render: h => h(App)
    }).$mount("#app");
  }
});
